html {
    height: -webkit-fill-available !important;
}

body {
    height: -webkit-fill-available !important;
}

.PhoneInput {
    width: 100%;
    margin-top: 10px;
}

.PhoneInputCountry {
    width: 65px;
    border: 1px solid #8f8f8f;
    max-height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.PhoneInputInput {
    padding: 15px;
    border: 1px solid #8f8f8f;
    font-size: 1rem;
    max-height: 45px;
    border-radius: 5px;
}

.react-tel-input {
margin-top: 10px;
}

.flag-dropdown {
    width: 45px;
    border: 1px solid #8f8f8f;
    background-color: #f5f5f5;
    max-height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.react-tel-input input {
    padding: 15px !important;
    border: 1px solid #8f8f8f !important;
    font-size: 1rem !important;
    max-height: 45px !important;
    height: 45px !important;
    width: 100% !important;
    padding-left: 50px !important;
    border-radius: 5px !important;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #2db838;
    border-style: dashed;
    border-radius: 5px;
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
    background: #eafaec;
    margin-top: 5px;
}

